import React from "react";

import About from "./About";
import { ds } from "../../../DataSource";
import ClientList from "./ClientList";
import SupplyList from "./SupplyList";
import { Link, NavLink } from "react-router-dom";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../../utils/BaseComponent";
import { ROUTER_HOME } from "../../home/Router";
import { Overlay, OverlayProps } from "@schneiderpp/utils-components";

const ROUTER_SALESMAN_DETAILS_BASE = "/Salesman/Details";

export const ROUTER_SALESMAN_DETAILS = {
    Base: ROUTER_SALESMAN_DETAILS_BASE,
    ClientList: ROUTER_SALESMAN_DETAILS_BASE + "/ClientList",
    SupplyList: ROUTER_SALESMAN_DETAILS_BASE + "/SupplyList",
    About: ROUTER_SALESMAN_DETAILS_BASE + "/About"
};

interface DetailsState {
    providedSalesmanId: number | undefined;
    datasource: {
        getDetails: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanDetails>;
    };
}

class SalesmanDetails extends BaseComponent<RouteComponentProps, DetailsState> {
    state: DetailsState = {
        providedSalesmanId: undefined,
        datasource: {
            getDetails: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Salesman.GetSalesmanDetails, this, "getDetails", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const SalesmanId = urlParams.get("SalesmanId");

        if (!SalesmanId) {
            return;
        }
        const SalesmanIdInt = parseInt(SalesmanId);
        if(!Number.isInteger(SalesmanIdInt)) {
            return
        }
        this.setState({ providedSalesmanId: SalesmanIdInt }, () => this.getDetails());
    }
    render() {
        if (!this.state.providedSalesmanId) {
            return (
                <div className="page">
                    <div className="page__header">Nie wczytano SalesmanId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link to={ROUTER_HOME.Salesman.List} className="button clear no-left-padding">
                            <span className="button__icon">arrow_back_ios</span> wróć do listy Handlowców
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">{dsDetailsData.response.Name}</div>
                        <div className="page-links">
                            <NavLink to={{ ...this.props.location, pathname: ROUTER_SALESMAN_DETAILS.About }} className="page-links__link">
                                O Handlowcy
                            </NavLink>
                            <NavLink
                                to={{ ...this.props.location, pathname: ROUTER_SALESMAN_DETAILS.ClientList }}
                                className="page-links__link"
                            >
                                Lista Klientow
                            </NavLink>
                            <NavLink
                                to={{ ...this.props.location, pathname: ROUTER_SALESMAN_DETAILS.SupplyList }}
                                className="page-links__link"
                            >
                                Lista Zatowarowań
                            </NavLink>
                        </div>
                        <Switch>
                            <Route path={ROUTER_SALESMAN_DETAILS.About}>
                                <About Details={dsDetailsData.response} />
                            </Route>
                            <Route path={ROUTER_SALESMAN_DETAILS.ClientList}>
                                <ClientList salesmanId={this.state.providedSalesmanId} />
                            </Route>
                            <Route path={ROUTER_SALESMAN_DETAILS.SupplyList}>
                                <SupplyList salesmanId={this.state.providedSalesmanId} />
                            </Route>
                            <Redirect path="*" to={ROUTER_SALESMAN_DETAILS.ClientList} />
                        </Switch>
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        )
    }

    get overlayProps(): OverlayProps {
        if(this.dsDetails.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            }
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={()=> this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            }
        }
        return {
            show: false
        }
    }

    private getDetails() {
        if (!this.state.providedSalesmanId) {
            return;
        }
        this.dsDetails.request({
            params: { SalesmanId: this.state.providedSalesmanId }
        });
    }
}

export default SalesmanDetails;

import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, Form, FormSelect, FormTextField } from "@schneiderpp/utils-forms";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";

interface ChangeFormFields {
    StatusId: string;
    TrackingLink: string;
}

interface ChangeState {
    providedOrderId: number | undefined;
    backToClient: boolean;
    fields: ChangeFormFields;
    fieldErrors: FormValidationError<ChangeFormFields>[];
    datasource: {
        OrderDetails: ParentStateDatasource<typeof Endpoint.Order.GetOrderDetails>;
        OrderStatusList: ParentStateDatasource<typeof Endpoint.Order.GetOrderStatusList>;
        OrderChangeStatus: ParentStateDatasource<typeof Endpoint.Order.PostOrderChangeStatus>;
    };
}

class ChangeOrderStatus extends BaseComponent<RouteComponentProps, ChangeState> {
    state: ChangeState = {
        providedOrderId: undefined,
        backToClient: false,
        fields: {
            StatusId: "",
            TrackingLink: ""
        },
        fieldErrors: [],
        datasource: {
            OrderDetails: DataSourceStateIdle,
            OrderStatusList: DataSourceStateIdle,
            OrderChangeStatus: DataSourceStateIdle
        }
    };

    private dsOrderStatusList = ds(Endpoint.Order.GetOrderStatusList, this, "OrderStatusList", () => this.context);
    private dsDetails = ds(Endpoint.Order.GetOrderDetails, this, "OrderDetails", () => this.context);
    private dsOrderChangeStatus = ds(Endpoint.Order.PostOrderChangeStatus, this, "OrderChangeStatus", () => this.context);

    private form = new Form<ChangeFormFields>(
        this,
        async () => [],
        (code) => code
    );

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const backToClient = urlParams.get("BackToClient");
        if (!!backToClient && backToClient === "true") {
            this.setState({ backToClient: true });
        }
        const OrderId = urlParams.get("OrderId");
        if (!OrderId) {
            return;
        }
        const OrderIdInt = parseInt(OrderId);
        if (!Number.isInteger(OrderIdInt)) {
            return;
        }
        this.setState({ providedOrderId: OrderIdInt }, () => {
            this.getOrderStatusList();
            this.getOrderDetails();
        });
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link
                        to={{
                            pathname: ROUTER_HOME.Orders.Details,
                            search: `OrderId=${this.state.providedOrderId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                        }}
                        className="button clear no-left-padding"
                    >
                        <span className="button__icon">arrow_back_ios</span>
                        wróć do Detali zamówienia nagród
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Zmień Status zamówienia nagród</div>
                    <div className="page-form">
                        <FormSelect
                            config={this.form.getFieldConfig("StatusId")}
                            label="Status"
                            options={this.orderStatusList.map((c) => ({ value: c.OrderStatusId.toString(), label: c.Name }))}
                        />
                        <FormTextField config={this.form.getFieldConfig("TrackingLink")} label="Tracking Link" />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get orderStatusList() {
        const dsOrderStatusListData = this.dsOrderStatusList.dataSourceStorage;
        if (dsOrderStatusListData.state === "completed") {
            return dsOrderStatusListData.response.orderStatuses;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsOrderChangeStatus.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsOrderChangeStatus.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsOrderChangeStatus.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (
            this.dsOrderStatusList.state === "pending" ||
            this.dsOrderStatusList.state === "idle" ||
            this.dsDetails.state === "pending" ||
            this.dsDetails.state === "idle"
        ) {
            return {
                show: true,
                title: "Ładowanie.."
            };
        }
        if (this.dsOrderStatusList.state === "error") {
            return {
                show: true,
                title: "nie wczytano listy",
                description: typeof this.dsOrderStatusList.error === "string" ? this.dsOrderStatusList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getOrderStatusList()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }

        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "nie wczytano listy",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getOrderDetails()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getOrderStatusList() {
        await this.dsOrderStatusList.request({});
    }

    private async getOrderDetails() {
        if (!this.state.providedOrderId) {
            return;
        }
        await this.dsDetails.request({ params: { OrderId: this.state.providedOrderId } });
        const dsDetails = this.dsDetails.dataSourceStorage;
        if (dsDetails.state === "completed") {
            this.setState((p) => ({
                fields: {
                    ...p.fields,
                    StatusId: dsDetails.response.Status.OrderStatusId.toString(),
                    TrackingLink: dsDetails.response.TrackingLink
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedOrderId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsOrderChangeStatus.request({
                data: {
                    OrderStatusId: parseInt(this.state.fields.StatusId),
                    OrderId: this.state.providedOrderId,
                    TrackingLink: this.state.fields.TrackingLink
                }
            });
            if (this.dsOrderChangeStatus.dataSourceStorage.state === "completed") {
                this.props.history.push({
                    pathname: ROUTER_HOME.Orders.Details,
                    search: `OrderId=${this.state.providedOrderId}${this.state.backToClient ? "&BackToClient=true" : ""}`
                });
            }
        }
    }
}

export default withRouter(ChangeOrderStatus);

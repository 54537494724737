import React from "react";

import { Endpoint } from "@schneiderpp/admin-endpoint";
import { Link } from "react-router-dom";
import { EndpointDefinitionGetResponse } from "@schneiderpp/utils-endpoint";
import { ROUTER_HOME } from "../../home/Router";

interface Props {
    Details: EndpointDefinitionGetResponse<typeof Endpoint.Salesman.GetSalesmanDetails>;
}

const About: React.FC<Props> = ({ Details }) => {
    return (
        <>
            <div className="page-buttons">
                <Link to={{ pathname: ROUTER_HOME.Salesman.Edit, search:`SalesmanId=${Details.SalesmanId}` }} className="button small">
                    Edytuj Handlowca
                </Link>
            </div>

            <div className="page-tab-content">
                <div className="page-details">
                    <div className="page-details__label">Nazwa Handlowcy:</div>
                    <div className="page-details__value">{Details.Name}</div>
                </div>
                <div className="page-details">
                    <div className="page-details__label">Email Handlowcy:</div>
                    <div className="page-details__value">{Details.Email}</div>
                </div>
                <div className="page-details">
                    <div className="page-details__label">Utworzono przez admina:</div>
                    <div className="page-details__value">{Details.Admin.Name}</div>
                </div>
                <div className="page-buttons">
                    <Link
                        to={{ pathname: ROUTER_HOME.Salesman.SetNewPassword, search: `SalesmanId=${Details.SalesmanId}` }}
                        className="button small"
                    >
                        Zmień Hasło
                    </Link>
                </div>
            </div>
        </>
    );
};

export default About;

import React from "react";

import { BaseComponent } from "../../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ds } from "../../../DataSource";
import { ROUTER_HOME } from "../../home/Router";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface SupplyListState {
    datasource: {
        SupplyList: ParentStateDatasource<typeof Endpoint.Client.GetClientSupplyList>;
    };
}

interface SupplyListProps {
    clientId: number;
}

class SupplyList extends BaseComponent<RouteComponentProps & SupplyListProps, SupplyListState> {
    state: SupplyListState = {
        datasource: {
            SupplyList: DataSourceStateIdle
        }
    };

    private dsSupplyList = ds(Endpoint.Client.GetClientSupplyList, this, "SupplyList", () => this.context);

    componentDidMount() {
        this.getSupplyList();
    }

    render() {
        return (
            <div className="page-tab-content">
                <div className="page-buttons">
                    <Link to={{ pathname: ROUTER_HOME.Supplies.Add, search: `ClientId=${this.props.clientId}` }} className="button small">
                        Dodaj zatowarowanie
                    </Link>
                </div>
                <div className="page-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-3 t-left">Data Zatowarowania</th>
                                <th className="w-3">Status</th>
                                <th className="w-3 t-right">Kwota</th>
                                <th className="w-3 t-right">Nagroda</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.supplyList.map((supply) => (
                                <tr
                                    className="clickable"
                                    key={supply.SupplyId}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: ROUTER_HOME.Supplies.Details,
                                            search: `SupplyId=${supply.SupplyId}&BackToClient=true`
                                        })
                                    }
                                >
                                    <td className="t-left">{moment(supply.SupplyDate).format("YYYY-MM-DD")}</td>
                                    <td className="t-center">{supply.Status.Name}</td>
                                    <td className="t-right">{FormatFloat(supply.Value)} ZŁ</td>
                                    <td className="t-right">{FormatFloat(supply.BonusValue)} PKT</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get supplyList() {
        const dsSupplyListData = this.dsSupplyList.dataSourceStorage;
        if (dsSupplyListData.state === "completed") {
            return dsSupplyListData.response.Supplies;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsSupplyList.state === "pending" || this.dsSupplyList.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsSupplyList.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsSupplyList.error === "string" ? this.dsSupplyList.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getSupplyList()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getSupplyList() {
        await this.dsSupplyList.request({
            params: {
                ClientId: this.props.clientId
            }
        });
    }
}

export default withRouter(SupplyList);

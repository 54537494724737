import React from "react";

import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint, GetSalesmanClients } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../../utils/BaseComponent";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ds } from "../../../DataSource";
import { ROUTER_HOME } from "../../home/Router";

interface ClientListState {
    providedSalesmanId: number | undefined;
    datasource: {
        ClientList: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanClients>;
    };
}

interface ClientListProps {
    salesmanId: number;
}

class ClientList extends BaseComponent<RouteComponentProps & ClientListProps, ClientListState> {
    state: ClientListState = {
        providedSalesmanId: undefined,
        datasource: {
            ClientList: DataSourceStateIdle
        }
    };

    private dsClientList = ds(Endpoint.Salesman.GetSalesmanClients, this, "ClientList", () => this.context);

    componentDidMount() {
        this.getClientList();
    }

    render() {
        return (
            <div className="page-tab-content">
                <div className="page-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-4 t-left">Nazwa</th>
                                <th className="w-4 t-center">Email</th>
                                <th className="w-4">Firma</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.clientList.map((client) => (
                                <tr
                                    className="clickable"
                                    key={client.ClientId}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: ROUTER_HOME.Client.Detials.Base,
                                            search: `ClientId=${client.ClientId}`
                                        })
                                    }
                                >
                                    <td className="t-left">{client.Name}</td>
                                    <td className="t-center">{client.Email}</td>
                                    <td className="t-center">{client.Company}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get clientList() {
        const dsClientListData = this.dsClientList.dataSourceStorage;
        if (dsClientListData.state === "completed") {
            return dsClientListData.response.Clients;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsClientList.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsClientList.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsClientList.error === "string" ? this.dsClientList.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getClientList()}>
                            Spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getClientList() {
        await this.dsClientList.request({
            params: {
                SalesmanId: this.props.salesmanId
            }
        });
        if (this.dsClientList.dataSourceStorage.state === "completed") {
            this.setState((p) => ({}));
        }
    }
}

export default withRouter(ClientList);

import { useState, useRef } from "react";

import { EndpointDefinition, EndpointArgs, EndpointDefinitionGetResponse } from "@schneiderpp/utils-endpoint";

import { ep } from "../DataSource";
import { ContextApp } from "../context";

type DatasourceCancel = () => void;

export interface DatasourceStateIdle {
    state: "idle";
}

export interface DatasourceStatePending {
    state: "pending";
}

export interface DatasourceStateCompleted<TEndpoint extends EndpointDefinition<any, any, any, boolean>> {
    response: EndpointDefinitionGetResponse<TEndpoint>;
    state: "completed";
}

export interface DatasourceStateError {
    state: "error";
    message: string;
    code: string;
    data: any;
}
export type DatasourceState<TEndpoint extends EndpointDefinition<any, any, any, boolean>> =
    | DatasourceStateIdle
    | DatasourceStatePending
    | DatasourceStateCompleted<TEndpoint>
    | DatasourceStateError;

export const datasourceStateIdle: DatasourceStateIdle = { state: "idle" };

export function useDatasource<TEndpointDefintion extends EndpointDefinition<any, any, any, boolean>>(
    endpointDefinition: TEndpointDefintion
): {
    state: DatasourceState<TEndpointDefintion>;
    load: (args: EndpointArgs<TEndpointDefintion>, context: ContextApp) => void;
    cancel: () => void;
    reset: () => void;
} {
    const [state, setState] = useState<DatasourceState<TEndpointDefintion>>({ ...datasourceStateIdle });
    const cancelToken = useRef<DatasourceCancel | undefined>(undefined);

    const cancelRef = useRef(() => {
        if (cancelToken.current) {
            cancelToken.current();
            cancelToken.current = undefined;
        }
    });

    const loadRef = useRef(async (args: EndpointArgs<TEndpointDefintion>, contextApp: ContextApp) => {
        cancelRef.current();

        setState({ state: "pending" });

        const endpoint = ep(endpointDefinition, contextApp);

        cancelToken.current = endpoint.cancel;

        const endpointResponse = await endpoint.request(args);

        // const endpointResponse = await endpoint(endpointDefinition, args, undefined, cancelFunction => {
        //     cancelToken.current = cancelFunction;
        // });

        if (endpointResponse[0] !== undefined) {
            if (endpointResponse[0].errorCode === "AxiosCancelError") return;

            const errorState: DatasourceStateError = {
                state: "error",
                code: endpointResponse[0].errorCode,
                message: endpointResponse[0].errorMessage,
                data: endpointResponse[0].errorData
            };
            setState(errorState);
        } else {
            setState({ state: "completed", response: endpointResponse[1] });
        }
    });

    const resetRef = useRef(() => {
        cancelRef.current();
        setState({ state: "idle" });
    });

    return {
        state,
        load: loadRef.current,
        cancel: cancelRef.current,
        reset: resetRef.current
    };
}

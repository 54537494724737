import React from "react";

import { Link } from "react-router-dom";
import { EndpointDefinitionGetResponse } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { ROUTER_HOME } from "../../home/Router";
import { FormatFloat } from "@schneiderpp/utils-generic";
import { ContextApp } from "../../../context";
import { useDatasource } from "../../../hooks/useDatasource";

interface Props {
    Details: EndpointDefinitionGetResponse<typeof Endpoint.Client.GetClientDetails>;
    contextApp: ContextApp;
}

const About: React.FC<Props> = ({ Details, contextApp }) => {
    return (
        <>
            <div className="page-buttons">
                <Link to={{ pathname: ROUTER_HOME.Client.Edit, search: `ClientId=${Details.ClientId}` }} className="button small">
                    Edytuj Klienta
                </Link>
                <SendNotifyEmailButton clientId={Details.ClientId} contextApp={contextApp} />
            </div>
            <div className="page-tab-content">
                <div className="page__column-view">
                    <div className="page__column">
                        <div className="page-details">
                            <div className="page-details__label">Imię i nazwisko</div>
                            <div className="page-details__value">{Details.Name}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Email Klienta</div>
                            <div className="page-details__value">{Details.Email}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Nazwa Firmy</div>
                            <div className="page-details__value">{Details.Company}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Nazwa Handlowcy</div>
                            <div className="page-details__value">
                                {Details.Salesman.Name}
                                <Link
                                    to={{
                                        pathname: ROUTER_HOME.Salesman.Details.About,
                                        search: `SalesmanId=${Details.Salesman.SalesmanId}`
                                    }}
                                    className="page-details__value-icon"
                                >
                                    launch
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="page__column">
                        <div className="page-details">
                            <div className="page-details__label">Stan Konta</div>
                            <div className="page-details__value">{FormatFloat(Details.Balance)} PKT</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Wszystkie przyznane punkty</div>
                            <div className="page-details__value">{FormatFloat(Details.TotalPoints)} PKT</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Adres dostawy</div>
                            {Details.AddressCompany ? <div className="page-details__value">{Details.AddressCompany}</div> : null}
                            <div className="page-details__value">{Details.Street}</div>
                            <div className="page-details__value">
                                {Details.City}, {Details.PostalCode}
                            </div>
                            <div className="page-details__value">{Details.AddressComment}</div>
                        </div>
                        <div className="page-details">
                            <div className="page-details__label">Numer telefonu</div>
                            <div className="page-details__value">{Details.Telephone}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;

const SendNotifyEmailButton: React.FC<{ clientId: number; contextApp: ContextApp }> = ({ clientId, contextApp }) => {
    const dsSendNotify = useDatasource(Endpoint.Client.PostClientSendNotify);

    if (dsSendNotify.state.state === "pending") {
        return <div className="page-details__button-action-info">Wysyłanie...</div>;
    }

    if (dsSendNotify.state.state === "error") {
        return <div className="page-details__button-action-info">Błąd podczas wysyłania</div>;
    }

    if (dsSendNotify.state.state === "completed") {
        return <div className="page-details__button-action-info">Wysłano!</div>;
    }

    return (
        <div className="button small" onClick={() => dsSendNotify.load({ data: { ClientId: clientId } }, contextApp)}>
            Wyślij email z przypomnieniem
        </div>
    );
};

import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface AddFormFields {
    Name: string;
    Description: string;
    Value: string;
}

interface Addstate {
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        Add: ParentStateDatasource<typeof Endpoint.Product.PostProductAdd>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async (fields) => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    if (!Number.isInteger(parseInt(fields.Value))) {
        errors.push({ fieldName: "Value", code: "MustBeNumber" });
    }
    if (parseInt(fields.Value) <= 0) {
        errors.push({ fieldName: "Value", code: "MustBeHigher" });
    }
    return errors;
};

class Add extends BaseComponent<RouteComponentProps, Addstate> {
    state: Addstate = {
        fields: {
            Name: "",
            Description: "",
            Value: ""
        },
        fieldErrors: [],
        datasource: {
            Add: DataSourceStateIdle
        }
    };

    private dsAdd = ds(Endpoint.Product.PostProductAdd, this, "Add", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, (code) => {
        switch (code) {
            case "MustBeNumber":
                return "Musi być numerem";
            case "MustBeHigher":
                return "Musi być większe niz 0";
            case "NameTooShort":
                return "Nazwa za krótka";
            default:
                return code;
        }
    });

    render() {
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Products.List} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span> wróć do listy produktów
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj nowy produkt</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                        <FormTextField config={this.form.getFieldConfig("Description")} label="Opis" />
                        <FormTextField config={this.form.getFieldConfig("Value")} label="Cena" type="number" />
                        <button className="button align-self-end margin-top-10" onClick={()=> this.submit()}>zatwierdź</button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsAdd.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsAdd.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsAdd.error === "string" ? this.dsAdd.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        }
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsAdd.request({
                data: {
                    Name: this.state.fields.Name,
                    Description: this.state.fields.Description,
                    Value: parseFloat(this.state.fields.Value)
                }
            });
            const dsAdd = this.dsAdd.dataSourceStorage;  
            if (dsAdd.state === "completed") {
                this.props.history.push({pathname: ROUTER_HOME.Products.Details, search: `ProductId=${dsAdd.response.ProductId}`});
            }
        }
    }
}

export default withRouter(Add);

import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface EditFormFields {
    Name: string;
}

interface EditState {
    providedSalesmanId: number | undefined;
    fields: EditFormFields;
    fieldErrors: FormValidationError<EditFormFields>[];
    datasource: {
        Edit: ParentStateDatasource<typeof Endpoint.Salesman.PostSalesmanEdit>;
        Details: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanDetails>;
    };
}

const editFormValidate: FormValidationFunction<EditFormFields> = async (fields) => {
    const errors: Array<FormValidationError<EditFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "SalesmanNameTooShort" });
    }
    return errors;
};

class Edit extends BaseComponent<RouteComponentProps, EditState> {
    state: EditState = {
        providedSalesmanId: undefined,
        fields: {
            Name: ""
        },
        fieldErrors: [],
        datasource: {
            Edit: DataSourceStateIdle,
            Details: DataSourceStateIdle
        }
    };

    private dsEdit = ds(Endpoint.Salesman.PostSalesmanEdit, this, "Edit", () => this.context);

    private dsDetails = ds(Endpoint.Salesman.GetSalesmanDetails, this, "Details", () => this.context);

    private form = new Form<EditFormFields>(this, editFormValidate, (code) => {
        switch (code) {
            case "SalesmanNameTooShort":
                return "Nazwa za krótka";
            default:
                return code;
        }
    });

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const SalesmanId = urlParams.get("SalesmanId");
        if (!SalesmanId) {
            return;
        }
        const SalesmanIdInt = parseInt(SalesmanId);
        if (!Number.isInteger(SalesmanIdInt)) {
            return;
        }
        this.setState({ providedSalesmanId: SalesmanIdInt }, () => {
            this.getDetails();
        });
    }

    render() {
        if (!this.state.providedSalesmanId) {
            return (
                <div className="page">
                    <div className="page__haeder">Nie odnaleziono SalesmanId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link
                            to={{ pathname: ROUTER_HOME.Salesman.Details.About, search: `SalesmanId=${this.state.providedSalesmanId}` }}
                            className="button clear no-left-padding"
                        >
                            <span className="button__icon">arrow_back_ios</span> wróć do detali Handlowcy
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">Edytuj Handlowca</div>
                        <div className="page-form">
                            <FormTextField config={this.form.getFieldConfig("Name")} label="Nazwa" />
                            <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                                zatwierdź
                            </button>
                        </div>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending" || this.dsDetails.state === "idle" || this.dsEdit.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getDetails()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsEdit.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsEdit.error === "string" ? this.dsEdit.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsEdit.resetState()}>Spróbuj ponownie</button>
                    </div>
                )
            };
        }

        return {
            show: false
        };
    }

    private async getDetails() {
        if (!this.state.providedSalesmanId) {
            return;
        }
        await this.dsDetails.request({
            params: { SalesmanId: this.state.providedSalesmanId }
        });
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            const response = dsDetailsData.response;
            this.setState(() => ({
                fields: {
                    Name: response.Name
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedSalesmanId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsEdit.request({
                data: {
                    Name: this.state.fields.Name,
                    SalesmanId: this.state.providedSalesmanId
                }
            });
            if (this.dsEdit.dataSourceStorage.state === "completed") {
                this.props.history.push({
                    pathname: ROUTER_HOME.Salesman.Details.About,
                    search: `SalesmanId=${this.state.providedSalesmanId}`
                });
            }
        }
    }
}

export default withRouter(Edit);
